import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titleList,
      handleClick: _vm.handleClick,
      showNumb: true
    }
  }), _c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    key: "",
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: 1200
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "imgurls" ? _c("span", [record.imgurls ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: record.imgurls.split("|")[0]
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.imgurls);
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "sellertype" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.sellerType(record.sellertype)))])]) : key == "productName" ? _c("span", [_c("span", [_vm._v(_vm._s(record.list[0].productname.slice(0, 15)) + _vm._s(record.list[0].productname.length > 15 ? "..." : ""))])]) : key == "status" ? _c("span", [record.status == 5 ? _c("span", [_vm._v(" " + _vm._s(record.statusdesc || "---") + " ")]) : _c("span", [_vm._v(_vm._s(_vm.saleOrderStatus(record.status)))])]) : key == "action" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["saleOrder:index:detail"],
              expression: "['saleOrder:index:detail']"
            }],
            on: {
              click: function click($event) {
                return _vm.toDetail(record);
              }
            }
          }, [_vm._v("订单详情 ")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["saleOrder:index:addressInfo"],
              expression: "['saleOrder:index:addressInfo']"
            }],
            on: {
              click: function click($event) {
                return _vm.addressinfo(record);
              }
            }
          }, [_vm._v("物流信息")])], 1) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };