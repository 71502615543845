var searchKeys = [{
  key: 'dates',
  label: '创建时间',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始日期", "结束日期"],
  dates: true,
  required: false,
  rules: [{
    required: false
  }]
}, {
  key: "orderno",
  label: "单号",
  placeholder: "订单号/物流单号",
  required: false,
  rules: [],
  input: true
},
// {
//     key: "source",
//     label: "订单来源",
//     placeholder: "全部",
//     required: false,
//     rules: [],
//     select: true
// },
{
  key: "sellerType",
  label: "商家类型",
  placeholder: "全部",
  required: false,
  rules: [],
  select: true
}, {
  key: "skuNo",
  label: "货号",
  placeholder: "货号",
  required: false,
  rules: [],
  input: true
}, {
  key: "productName",
  label: "商品名称",
  placeholder: "商品名称",
  required: false,
  rules: [],
  input: true
}, {
  key: "userEmail",
  label: "买家邮箱",
  placeholder: "买家邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "sellerEmail",
  label: "卖家邮箱",
  placeholder: "卖家邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };